<template>
    <div>
        <ul class="navbar-nav nav">
            <b-nav-item :class="{'active': $route.name =='posts-index'}" to="/">
                Home
            </b-nav-item>
            <b-nav-item :class="{'active': $route.name =='about-us'}" :to="{ name: 'about-us'}">
                About us
            </b-nav-item>
        </ul>
    </div>
</template>

<script>
import {
  BNavbar, BNavbarNav, BNavItem,BLink 
} from 'bootstrap-vue'
export default {
  components: {
      BNavbar,
      BNavbarNav,
      BNavItem,
      BLink
  },
}
</script>
