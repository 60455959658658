<template>
    <div >
        <ul class="navbar-nav nav">
            <b-nav-item :class="{'active': $route.name =='contact-us'}"  :to="{ name: 'contact-us'}">
                Contact us
            </b-nav-item>
            <b-nav-item :class="{'active': $route.name =='bug-report'}" :to="{ name: 'bug-report'}">
                Report bug
            </b-nav-item>
        </ul>
    </div>
</template>

<script>
import {
  BNavbar, BNavbarNav, BNavItem,BLink
} from 'bootstrap-vue'
export default {
  components: {
      BNavbar,
      BNavbarNav,
      BNavItem,
      BLink
  }
}
</script>