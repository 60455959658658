<template>
    <b-dropdown id="dropdown-grouped" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="link" right class="dropdown-icon-wrapper" no-caret>
        <template #button-content>
            <b-nav-item>
                <feather-icon icon="CalendarIcon" size="18" class="align-middle" />
            </b-nav-item>
        </template>
        <b-dropdown-item>
            {{ date }}
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
    import { BDropdown, BDropdownItem, BNavItem } from "bootstrap-vue";
    import Ripple from "vue-ripple-directive";

    export default {
        name: "header-date",
        components: { BDropdown, BDropdownItem, BNavItem },
        directives: {
            Ripple,
        },
        computed: {
            date() {
                return new Date().toLocaleString();
            },
        },
    };
</script>
