<template>
    <div class="navbar-header ml-xl-0 ml-1">
        <ul class="nav navbar-nav">
            <li class="nav-item">
                <b-link class="navbar-brand" to="/">
                    <!-- <span class="brand-logo">
            <b-img
              :src="appLogoImage"
              alt="logo"
            />
          </span> -->
                    <b-img
                        :src="logo"
                        class="brand-logo-image"
                        alt="soclib logo"
                        width="120"
                    />
                </b-link>
            </li>
        </ul>
    </div>
</template>

<script>
import store from "@/store/index";
import { BLink, BImg } from "bootstrap-vue"
import { $themeConfig } from "@themeConfig";

export default {
    components: {
        BLink,
        BImg,
    },
    computed: {
        logo() {
            return require("@/assets/images/logo/logo_158.png");
        },
    },
    setup() {
        // App Name
        const { appName, appLogoImage } = $themeConfig.app;
        return {
            appName,
            appLogoImage,
        };
    },
};
</script>

<style>
</style>
